<script>
export default {
    name: 'JetSnackBar',
    props: {
        msg: {
            type: Object,
            required: true,
            default: function(){return {text:''};}
        }
    },
    data() {
        return {
            visi: false
        };
    },
    computed: {
        show: {
            get: function(){
                return this.visi && (!!this.msg) && !$utils.isEmpty(this.msg.text);
            }
        }
    },
    methods: {
        hide: function() {
            this.visi = false;
        }
    },
    watch: {
        msg: function(val){
            //console.log('msg', val);
            this.visi = (!!val) && !$utils.isEmpty(val.text);
        }
    },
    render: function(h){
        console.log('render SB:', this);
        if (this.show){
            var top = this.msg.bottom;
            var bottom = (!!top) ? false : this.msg.bottom || true;
            return h('v-snackbar', {
                        props: {
                            color: this.msg.color,
                            timeout: this.msg.timeout || 6000,
                            bottom: bottom,
                            top: top,
                            value: true
                        },
                        domProps: {innerHTML: this.msg.text}
                    }, [
                        h('v-divider',{props:{dark:true, vertical:true}}),
                        h('v-btn', {
                            props: {dark:true, text:true},
                            on: {click: this.hide}
                        }, 'закрыть')
                    ]);
        }
        return h('v-snackbar', {props: {value: false}});
    }
}
</script>
